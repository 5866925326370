.main-mobile {
  background-image: url("../../../images/parallax/no-parallax.webp");
  background-size: cover;
  background-position-y: bottom;
  padding: 55px 0;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;
  justify-items: center;
}

.main-mobile__logo {
  width: 326px;
  height: 115px;
  object-fit: contain;
  margin-bottom: 19px;
}

.main-mobile__heading {
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  font-weight: normal;
}

@media (min-width: 768px) {
  .main-mobile {
    padding-bottom: 32px;
  }

  .main-mobile__logo {
    width: 429px;
    height: 151px;
  }

  .main-mobile__button {
    justify-self: end;
    margin-right: 64px;
  }
}
