.menu-button {
  background: url('../../images/menu-button.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 11;
}

.menu-button_hidden {
  width: 0;
  height: 0;
}

@media (min-width: 1024px) {
  .menu-button {
    display: none;
  }
}