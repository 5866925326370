.parallax {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../../images/parallax/7.webp");
  background-color: transparent;
  background-size: cover;
}

.parallax::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 70, 70, 0.5);
  z-index: 8;
  mix-blend-mode: soft-light;
}

.parallax__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: scale(1.1);
  object-fit: cover;
}

.parallax__depth1 {
  transform: scale(1.2);
  z-index: 2;
}

.parallax__depth2 {
  transform: scale(1.2);
  z-index: 3;
}

.parallax__depth3 {
  z-index: 4;
}

.parallax__depth4 {
  z-index: 5;
}

.parallax__depth5 {
  z-index: 6;
}

.parallax__depth6 {
  z-index: 7;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.content__heading {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: "Lighthaus";
  font-size: 140px;
  font-weight: normal;
  z-index: 5;
}

.content__round-text {
  background-image: url("../../../images/parallax/text.png");
  position: absolute;
  top: 51.5%;
  left: 45.8%;
  width: 191px;
  height: 191px;
  object-fit: contain;
  transform: translate(-50%, -50%);
  animation: rotate 180s linear infinite;
}

.content__logo {
  position: absolute;
  top: 78%;
  left: 71%;
  width: 234px;
  height: 85px;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

@media (min-width: 1550px) {
  .content__heading {
    font-size: 110px;
  }

  .content__round-text {
    width: 130px;
    height: 130px;
    left: 46.8%;
  }
}

@media (min-width: 2560px) {
  .content__round-text {
    top: 52%;
    left: 46%;
    width: 250px;
    height: 250px;
  }

  .content__heading {
    font-size: 200px;
  }

  .content__logo {
    width: 350px;
    height: auto;
    top: 89%;
    left: 77%;
  }
}

@media (min-width: 3840px) {
  .parallax__layer {
    width: 110%;
    height: 110%;
  }

  .content__heading {
    font-size: 350px;
  }

  .content__logo {
    width: 500px;
    height: 250px;
  }

  .content__round-text {
    width: 380px;
    height: 380px;
    left: 51%;
    top: 56%;
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}