.header {
  min-height: 86px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-template-areas: "left-button text right-button ";
  justify-items: center;
  align-items: center;
  grid-area: header;
  padding: 20px;
  background: inherit;
}

.header__button {
  width: 28px;
  height: 28px;
}

.header__button:first-of-type {
  grid-area: left-button;
}

.header__button:last-of-type {
  grid-area: right-button;
}

.header__button-image {
  height: 100%;
}

.header__cart {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url("../../images/cart-gold.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
}

.header__cart-counter {
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
  color: var(--gold);
}

.header__text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 65%;
  grid-area: text;
}

.header__text {
  font-family: Lighthaus;
  color: var(--gold);
  text-align: center;
  font-size: 20px;
  font-weight: normal;
}

.header__subtext {
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
}

@media (min-width: 500px) {
  .header__text {
    font-size: 32px;
  }

  .header__text_small {
    font-size: 26px;
  }
}

@media (min-width: 1024px) {
  .header {
    padding: 20px 40px;
    grid-template-columns: 110px 1fr 110px;
  }
}
