.item-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    "image"
    "info";
  padding-top: 10px;
  overflow: hidden;
  opacity: 0;
  display: none;
  z-index: 10;
  padding-top: 20px;
}

.item-popup__image-container {
  position: relative;
}

.item-popup__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  grid-area: image;
}

.item-popup__close-button {
  background-image: url("../../images/close-gold.svg");
  position: absolute;
  right: 25px;
  top: 30px;
  width: 28px;
  height: 28px;
  z-index: 20;
}

.item-popup__material {
  background-image: url("../../images/arrow-black.svg");
  background-repeat: no-repeat;
  background-size: 12px 20px;
  background-position: 40px;

  position: absolute;
  display: block;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  top: 50%;
}

.item-popup__info {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  grid-area: info;
}

.item-popup__sliders {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-bottom: 10px;
}

.item-popup__slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-popup__slider-text {
  font-family: "Century";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  text-align: center;
}

.item-popup__text-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 30px;
  border-top: 1px solid #ffba80;
  border-bottom: 1px solid #ffba80;
}

.item-popup__text {
  font-family: "Century";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-align: center;
}

@media (min-width: 1024px) {
  .item-popup {
    padding-top: 60px;
    width: calc(100% - 290px);
    transform: translateX(290px);
  }

  .item-popup__text {
    font-size: 16px;
  }
}

@media (min-width: 1500px) {
  .item-popup {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 350px;
    grid-template-areas: "image info";
    align-items: center;
    justify-items: center;
    padding: 0;
  }

  .item-popup__info {
    justify-content: space-between;
    gap: 10px;
    padding-top: 80px;
    border-left: 1px solid var(--gold);
  }

  .item-popup__text-container {
    flex-direction: column;
    gap: 25px;
    height: auto;
    border: none;
  }

  .item-popup__text {
    font-size: 21px;
    line-height: 26px;
  }

  .item-popup__item-name {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 30px;
  }

  .item-popup__text_big {
    font-family: "Lighthaus";
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 44px;
    color: var(--gold);
  }

  .item-popup__sliders {
    flex-direction: column;
    gap: 40px;
  }

  .item-popup__image-container {
    width: 100%;
    height: 100%;
    max-height: 900px;
  }
}

@media (min-width: 1750px) {
  .item-popup {
    width: calc(100% - 480px);
    transform: translateX(480px);
  }
}
