.about {
  width: 100%;
  height: 100%;
}

.about__container {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.about__container::-webkit-scrollbar {
  display: none;
}

.about__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  gap: 30px;
}

.about__heading {
  font-family: "Lighthaus";
  font-size: 34px;
  line-height: 44px;
  text-align: center;
  font-weight: normal;
}

.about__heading_big {
  font-size: 130px;
  text-align: center;
  line-height: 0.8;
}

.about__text {
  font-size: 13px;
  line-height: 16px;
  text-align: justify;
  max-width: 70%;
}

.about__text_small {
  font-size: 8px;
  line-height: 10px;
}

.about__centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
}

.about__history-heading {
  width: 100%;
  margin-bottom: 40px;
}

.about__materials-container {
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.about__materials {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 34px;
  width: 316px;
  border: 1px solid grey;
  border-radius: 33px;
  padding: 0 4px;
}

.about__material {
  width: 34px;
  height: 34px;
}

.about__material-button {
  width: 100%;
  height: 100%;
}

.about__meterial-image {
  width: 100%;
  height: 100%;
}

.about__product-image-container {
  position: relative;
  width: 297px;
  height: 463px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__product-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 5;
}

.about__gems-image {
  position: absolute;
  width: 238px;
  height: 358px;
  z-index: 3;
  transform: translateY(14px);
}

.about__circle {
  width: 158px;
  height: 158px;
  border-radius: 50%;
  background-color: white;

  position: absolute;
  z-index: 1;
}

/*  Индивидуальные стили */
.magi-image {
  width: 168px;
  height: 168px;
}

.image-with-text {
  width: 230px;
  height: 313px;
}

.annunciation-image {
  width: 284px;
  height: 346px;
  align-self: flex-start;
}

.star-icon {
  width: 20px;
  height: 20px;
}

.link-to-catalogue {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  height: 200px;
  padding-bottom: 40px;
  background-image: url("../../images/about/rays.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.link-to-catalogue__text {
  font-family: "Lighthaus";
  font-size: 34px;
  line-height: 44px;
  color: var(--gold);
  font-weight: normal;
}

.female-cross {
  position: relative;
  width: 270px;
  height: 410px;
}

.female-cross__heading-container {
  position: absolute;
  top: 50px;
  right: -40px;
  width: 108px;
  height: 55px;
  z-index: 1;
}

.female-cross__heading {
  font-family: "Lighthaus";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.085em;
}

.female-cross__arrow {
  position: absolute;
  bottom: 0;
  left: 30px;
  width: 25px;
  height: 12px;
}

.female-cross__circle {
  position: absolute;
  top: 65px;
  left: -45px;
}

.female-cross__text {
  position: absolute;
  max-width: 116px;
  top: 25px;
  left: -45px;
  z-index: 2;
  mix-blend-mode: difference;
}

.circle-button {
  width: 28px;
  height: 28px;
  background-image: url("../../images/spark-gold.svg");
  background-position-x: left;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 70%;
  border: 1px solid var(--gold);
  border-radius: 50%;
}

.product__circle {
  top: 136px;
  right: -33px;
}

/* Заголовок с линиями */
.heading-with-line {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.line {
  position: absolute;
  top: 50%;
  width: calc(50% - 170px);
  border: 0.5px solid white;
}

.line_left {
  left: 0;
}

.line_right {
  right: 0;
}

/* Заголовок об истории */
.history-heading {
  display: block;
  height: 34px;
  letter-spacing: 0.05em;
}

.history-heading-image {
  width: 100%;
  height: 62px;
  border-top: 1px white solid;
  border-bottom: 1px white solid;
  background-image: url("../../images/about/history-heading.png");
}

.history-heading_reverse {
  transform: rotateX(180deg);
  opacity: 50%;
}

/* Footer */
.footer {
  width: 100%;
}

.footer__heading {
  position: relative;
  height: 52px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid white;
  border-left: none;
  border-right: none;
}

.footer__heading-text {
  white-space: nowrap;
  transform: translateX(10vw);
}

.footer__contacts {
  height: 284px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer__contact {
  font-family: "Century";
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.06em;
  text-transform: uppercase;
}

.footer__text {
  font-family: "Century";
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 10px;
  text-align: center;

  border: 1px solid white;
  border-left: none;
  border-right: none;
  padding: 10px 0;
}

@media (min-width: 768px) {
  .about__section {
    margin-bottom: 60px;
  }

  .magi-image {
    width: 228px;
    height: 228px;
  }

  .about__history-heading {
    margin-bottom: 60px;
  }
}

@media (min-width: 1024px) {
  .about {
    display: grid;
    grid-template-columns: 290px 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "menu header"
      "menu main";
  }

  .about__container {
    width: calc(100vw - 290px);
  }
}

@media (min-width: 1280px) {
  .about__text {
    max-width: 620px;
  }

  .female-cross__text {
    max-width: 116px;
  }
}

@media (min-width: 1750px) {
  .about {
    grid-template-columns: 480px 1fr;
  }

  .about__container {
    width: calc(100vw - 480px);
  }
}

@media (min-width: 1920px) {
  .about__section {
    gap: 60px;
  }

  .about__heading {
    font-size: 55px;
    line-height: 71px;
  }

  .about__heading_big {
    font-size: 240px;
    line-height: 190px;
  }

  .about__text {
    font-size: 21px;
    line-height: 26px;
  }

  .about__text_small {
    font-size: 13px;
    line-height: 16px;
  }

  .history-heading {
    height: 54px;
  }

  .magi-image {
    width: 254px;
    height: 254px;
  }

  .image-with-text {
    width: 554px;
    height: 700px;
  }

  .female-cross {
    width: 620px;
    height: 600px;
  }

  .female-cross__heading-container {
    width: 285px;
    height: 135px;
  }

  .female-cross__heading {
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 0.085em;
  }

  .female-cross__arrow {
    width: 50px;
    height: 25px;
    left: 50px;
  }

  .female-cross__text {
    font-size: 13px;
    line-height: 16px;
    text-align: justify;
    letter-spacing: 0.07em;
    max-width: 194px;
    height: 237px;
    top: 50px;
    right: 0;
  }

  .female-cross__circle {
    width: 354px;
    height: 354px;
    top: 100px;
  }

  .annunciation-image {
    height: 766px;
    width: 630px;
  }

  .about__product-image {
    height: 710px;
    width: 455px;
  }

  .about__gems-image {
    height: 549px;
    width: 365px;
    transform: translateY(22px);
  }

  .about__product-image-container {
    width: 455px;
    height: 710px;
  }

  .product__circle {
    width: 376px;
    height: 376px;
    top: 80px;
    left: 146px;
  }

  .line {
    width: calc(50% - 300px);
  }
}