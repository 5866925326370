.item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item__text {
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.1em;
}

.item__text_small {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
}

.item__text_big {
  font-size: 34px;
  line-height: 42px;
  text-align: center;
  color: var(--gold);
  letter-spacing: 0.12em;
}

.item__images-container {
  width: 100%;
  height: 380px;
  position: relative;
  margin-bottom: 10px;
}

.item__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.item__image_gold {
  opacity: 1;
}

.item__image_silver {
  opacity: 0;
}

.item__info-container {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item__info {
  width: 100%;
  height: 100%;
  min-height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.item__interactive {
  width: 100%;
  min-height: 77px;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "material-slider info cart";
  align-items: center;
}

.item__cart {
  width: 46px;
  height: 46px;
  grid-area: cart;
  background-image: url("../../images/cart-white.svg");
  background-position-x: 2px;
  overflow: hidden;
}

.item__cart:hover {
  transform: scale(1.1);
}

.item__cart:disabled {
  opacity: .5;
}

.item__cart-container {
  width: 200%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateX(-50%);
}

.item__spark {
  width: 50%;
  height: 100%;
  background-image: url("../../images/spark-white.svg");
  background-repeat: no-repeat;
  background-position: 4px 23px;
  background-size: 24px;
}

.item__check {
  width: 50%;
  height: 100%;
  background-image: url("../../images/checkmark-white.svg");
  background-repeat: no-repeat;
  background-position: center 70%;
  background-size: 24px;
}

@media (min-width: 670px) {
  .item {
    padding-bottom: 20px;
  }
}