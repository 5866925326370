.enter-button {
  position: relative;
  height: 56px;
  width: 225px;
  align-self: end;
  z-index: 25;
}

.enter-button__link {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enter-button__text {
  font-family: "Lighthaus";
  font-size: 34px;
  line-height: 44px;
  font-weight: normal;
}

.enter-button__image {
  width: 56px;
  height: 56px;
  background-image: url("../../../images/spark-white.svg");
  background-repeat: no-repeat;
  background-size: 37px 19px;
  background-position-y: center;
  border: 1px solid white;
  border-radius: 50%;
}


@media (min-width: 2560px) {
  .enter-button {
    height: 76px;
    width: 300px;
  }

  .enter-button__text {
    font-size: 54px;
  }
}

@media (min-width: 3820px) {
  .enter-button {
    height: 300px;
    width: 500px;
  }

  .enter-button__text {
    font-size: 90px;
    line-height: normal;
  }

  .enter-button__image {
    width: 70px;
    height: 70px;
  }
}