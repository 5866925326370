.load-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: black;
  animation: logo-blink 2.5s infinite alternate;
  pointer-events: none;
}

.logo {
  opacity: 0;
  animation: show 1.5s forwards;
}

.load-screen::after {
  content: "";
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    ellipse,
    rgba(220, 100, 100, 0.5) 0%,
    rgba(0, 0, 0, 0) 70%
  );
  border-radius: 50%;
  mix-blend-mode: hard-light;

  opacity: 0;
  animation: light 1.9s infinite alternate;
}

.load-screen_hidden {
  opacity: 0;
  transition: 2s;
}

.load-screen_visible {
  opacity: 1;
  transition: 0.1s;
}

@keyframes logo-blink {
  from {
    filter: saturate(50%);
  }
  to {
    filter: saturate(100%);
  }
}

@keyframes light {
  from {
    width: 50%;
    height: 50%;
    opacity: 1;
  }
  to {
    width: 40%;
    height: 40%;
    opacity: 0.8;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
