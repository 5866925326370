@import url("../node_modules/modern-normalize/modern-normalize.css");

@font-face {
  font-family: "Lighthaus";
  src: url("./fonts/Lighthaus.otf");
}

@font-face {
  font-family: "Century";
  src: url("./fonts/Centurygothic.ttf");
}

html {
  --gold: #ffba80;
  --silver: #c4c4c4;
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Century", sans-serif;
  color: white;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}

button {
  background-color: transparent;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input {
  border: none;
}

textarea {
  resize: none;
  border: none;
}

fieldset {
  border: none;
}

a,
button {
  cursor: pointer;
  transition: 0.3s;
}

a:hover,
button:hover {
  opacity: 0.75;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.animate-bg {
  background: linear-gradient(90deg, #200000, #00041b);
  background-size: 200% 200%;

  -webkit-animation: AnimateBG 15s ease infinite;
  -moz-animation: AnimateBG 15s ease infinite;
  animation: AnimateBG 15s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}