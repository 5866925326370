#item-rotator {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  grid-area: item-rotator;

  filter: blur(5px);
  transition: filter 0.6s;
}

#item-rotator * {
  width: 100%;
  height: 100%;
}

.contain img {
  object-fit: contain;
}

.cover img {
  object-fit: cover;
}

.progress-bar {
  position: absolute;
  width: 240px;
  height: 10px;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 50%;
  z-index: 5;

  border: 0.5px solid black;
  border-radius: 20px;

  opacity: 1;
  transition: opacity 0.6s;
}

.progress-bar__line {
  background: linear-gradient(
    51deg,
    rgba(201, 128, 66, 1) 25%,
    rgba(255, 186, 128, 1) 75%
  );
  border-radius: inherit;
  width: 0;
  height: 100%;
  transition: width 0.6s;
}

@media (min-width: 1500px) {
  #item-rotator {
    height: 90%;
  }
}
