.size-slider {
  height: 40px;
  border: 1px solid white;
  border-radius: 30px;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.size-slider:hover {
  transform: scale(1.1);
}

.size-slider__input {
  display: none;
}

.size-slider__label {
  width: 100%;
  height: 100%;
  display: flex;
}

.size-slider__text {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Century";
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 17px;
  letter-spacing: 0.05em;
}

.size-slider__text::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  mix-blend-mode: difference;
  transition: .3s;
}

.size-slider__label .size-slider__input:checked+.size-slider__text:before {
  background: var(--silver);
}