.backward-button {
  width: 100%;
  height: 100%;
  color: var(--gold);
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: left-button;
}

.backward-button__image {
  transform: rotate(180deg);
  width: 22px;
  height: 11px;
}
