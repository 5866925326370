.details {
  width: 100%;
  height: 100%;
}

.details__container {
  width: 100%;
  height: calc(100% - 86px);
  grid-area: main;
  scroll-snap-type: y mandatory;
  justify-self: center;
  align-self: center;
  max-width: 1920px;
  max-height: 1080px;
  overflow-y: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.details__container::-webkit-scrollbar {
  display: none;
}

.details__item {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 85% 5% 5%;
  grid-template-columns: 100%;
  grid-template-areas:
    "item-rotator"
    "rotate"
    "arrow";
  align-content: flex-start;
  justify-items: center;
  align-items: center;

  scroll-snap-align: center;
  overflow: hidden;
}

.details__description {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 150px;
  justify-items: center;
  align-items: center;
  scroll-snap-align: center;
}

.details__arrow {
  background-image: url("../../images/arrow-down-gold.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  grid-area: arrow;
  width: 40px;
  height: 40px;
}

.details__text {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: block;
  white-space: pre-wrap;
  text-align: justify;
  hyphens: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.details__text::-webkit-scrollbar {
  width: 1px;
  background: gray;
}

.details__text::-webkit-scrollbar-thumb {
  background: white;
}

.details__text_big {
  font-family: "Lighthaus";
  font-weight: normal;
  font-size: 21px;
  text-align: center;
  color: var(--gold);
}

.details__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}

.details__item-type {
  text-align: center;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
}

.details__rotate-image {
  width: 50px;
}

.details__rotate-image-left {
  display: none;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s;
  transform: rotate(180deg);
}

.details__rotate-image-left:hover,
.details__rotate-image-right:hover {
  opacity: 0.75;
}

.details__rotate-image-right {
  display: none;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s;
}


.details__item-choose {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 206px;
}

.dateail__point-image {
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .details__item {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: calc(100% - 86px) 40px;
    row-gap: 15px;
    grid-template-areas:
      "left item-rotator right"
      "arrow arrow arrow";
    padding: 20px;
  }

  .details__text {
    font-size: 20px;
  }

  .details__text_big {
    font-size: 34px;
    line-height: 44px;
  }

  .dateail__point-image {
    margin: 20px;
  }

  .details__rotate-image {
    display: none;
  }

  .details__rotate-image-left {
    display: block;
    grid-area: left;
  }

  .details__rotate-image-right {
    display: block;
    grid-area: right;
  }
}

@media (min-width: 1024px) {
  .details {
    display: grid;
    grid-template-columns: 290px 1fr;
    grid-template-rows: 86px 1fr;
    grid-template-areas:
      "menu header"
      "menu main";
  }

  .details__container {
    height: 100%;
  }
}

@media (min-width: 1500px) {
  .details__item {
    position: relative;
  }

  .details__container {
    display: flex;
    overflow: auto;
    padding: 0 20px 20px 20px;
  }

  .details__description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 30%;
  }

  .details__arrow {
    display: none;
  }
}

@media (min-width: 1750px) {
  .details {
    grid-template-columns: 480px 1fr;
  }

  .details__container {
    padding: 0 40px 40px 49px;
  }
}