.menu__filter {
  padding-left: 15%;
  overflow: hidden;
  height: 0;
}

.menu__link_active {
  color: white;
}

.filter__input {
  display: none;
}

.filter__input:checked + .menu__link {
  color: white;
}

.plus {
  transition: opacity 0.3s;
}

.plus:hover {
  opacity: 0.75;
}

@keyframes show-category {
  from {
    height: 0;
  }
  to {
    height: 351px;
  }
}

@keyframes show-who {
  from {
    height: 0;
  }
  to {
    height: 101px;
  }
}
