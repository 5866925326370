.add-button {
  width: 100%;
  height: 120px;
  background-color: white;
  color: black;
}

.add-button:disabled {
  opacity: .5;
}

.add-button__price {
  font-size: 34px;
  text-align: center;
  text-transform: uppercase;
}

.add-button__text {
  font-size: 21px;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 1500px) {
  .add-button {
    background-color: transparent;
    color: white;
    border-top: 1px solid var(--gold);
  }

  .add-button_disabled {
    color: gray;
  }
}