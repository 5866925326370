.main-desktop {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.main-desktop__button {
  position: absolute;
  top: 20px;
  right: 50px;
}
