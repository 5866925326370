.menu {
  position: fixed;
  top: 0;
  width: 290px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid var(--gold);
  padding: 68px 29px 0 29px;
  align-items: center;
  z-index: 10;
  transform: translateX(-100%);
  grid-area: menu;

  display: none;
}

/* Подложка для перекрытия другого контента при анимации */
.menu::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 110%;
  height: 100%;
  z-index: -1;
  background: inherit;
}

/* Подложка для предотвращения клика вне меню */
.menu-over {
  content: "";
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.menu__logo {
  width: 186px;
  height: 66px;
  margin-bottom: 68px;
}

.menu__items {
  width: 100%;
  overflow-y: scroll;
  max-width: 200px;
  scrollbar-width: none;
  overflow-x: hidden;
}

.menu__items::-webkit-scrollbar {
  display: none;
}

.menu__item {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
}

.menu__item-image:hover {
  opacity: 0.75;
}

.menu__link {
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.1em;
  color: var(--gold);
  cursor: pointer;
  transition: 0.3s;
}

.menu__link:hover {
  opacity: 0.75;
}

@media (min-width: 1024px) {
  .menu {
    display: flex;
    grid-row: span 2;
    transform: translateX(0);
  }

  .menu-over {
    display: none;
  }
}

@media (min-width: 1750px) {
  .menu {
    width: 480px;
  }

  .menu__logo {
    width: 326px;
    height: 112px;
  }
}
