.catalogue {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "header"
    "items";
}

.catalogue__items {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  justify-content: center;
  grid-area: items;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.catalogue__items::-webkit-scrollbar {
  display: none;
}

@media (min-width: 670px) {
  .catalogue__items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .catalogue {
    grid-template-columns: 290px 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "menu header"
      "menu items";
  }
}

@media (min-width: 1450px) {
  .catalogue__items {
    grid-template-columns: repeat(3, 1fr);
  }

  .only-chains-template {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1750px) {
  .catalogue {
    grid-template-columns: 480px 1fr;
  }

  .only-chains-template {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 2300px) {
  .catalogue__items {
    grid-template-columns: repeat(auto-fill, minmax(360px, 500px));
    justify-content: space-around;
  }

  .only-chains-template {
    grid-template-columns: repeat(4, 1fr);
  }
}
